<template>
  <footer>
    <p>@2022 Designed and developed by <a href="#">Aimudo Oluwaseyi</a></p>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  font-family: var(--comic-family);
  margin-top: 3em;
  p {
    text-align: center;
    color: var(--white-color);
    a {
      text-decoration: none;
      color: var(--pink-color);
    }
  }
}
</style>